import { AModel } from '@/types/class_helper';
interface OpeningHours {
	monday: { open: boolean; start: string | null; end: string | null };
	tuesday: { open: boolean; start: string | null; end: string | null };
	wednesday: { open: boolean; start: string | null; end: string | null };
	thursday: { open: boolean; start: string | null; end: string | null };
	friday: { open: boolean; start: string | null; end: string | null };
	saturday: { open: boolean; start: string | null; end: string | null };
	sunday: { open: boolean; start: string | null; end: string | null };
}
export class Site extends AModel {
	id: string;
	name: string;
	address: string;
	directions: string;
	site_id?: string;
	lat: number;
	lng: number;
	opening_hours: OpeningHours | null;

	constructor(opt?: Partial<Site>) {
		super();

		// Default values (all props)
		this.id = '';
		this.name = '';
		this.address = '';
		this.directions = '';
		this.lat = 0;
		this.lng = 0;
		this.site_id = undefined;
		this.opening_hours = null;

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign(
				{},
				...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] }))
			);

			// Assign values passed to the constructor
			Object.assign<Site, Partial<Site>>(this, sanitized);
		}
	}

	copy(): Site {
		const copy = new Site(this.toObject());
		return copy;
	}
}

export default Site;
