import dayjs from 'dayjs';
import { SerializeStoreObject } from '@/types/class_helper';

import MISC_DATA from '@/store/modules/MiscDataModule';
import global from '~/mixins/global';

export enum GACustomEvent {
	upsell = 'Upsell_page',
	insurance = 'Insurance_page',
	summary = 'Summary_page',
	data_entry = 'Data_entry_page',
	where_is_truck = 'Where_is_truck',
	lzm_contact = 'lzm_kontakt',
	select_truck_calendar = 'select_truck_calendar',
	select_truck_picker = 'select_truck_picker',
	insurance_dialog_yes = 'upsell_protection_dialog_yes',
	insurance_dialog_no = 'upsell_protection_dialog_no',
	cancel_dialog_yes = 'upsell_cancel_dialog_yes',
	cancel_dialog_no = 'upsell_cancel_dialog_no',
	get_offer_clicked = 'checkout.offer',
	contract_draft_clicked = 'checkout.contract',
	phone_on_navbar = 'NavbarPhoneClicked',
	phone_on_contact_page = 'ContactPagePhoneClicked',
	phone_on_faq_page = 'FaqPagePhoneClicked'
}

export enum SummaryPanel {
	truck = 'truck',
	times = 'date_time',
	upsells = 'upsells',
	login = 'login',
	checkout = 'checkout'
}

export interface ICountry {
	code: string;
	pcp: string;
	zone: number | null;
	path?: string;
	name?: string;
}

export interface IContact {
	name: string;
	email: string;
	phone: {
		free: string;
		paid: string;
		paid_formatted: string;
		free_formatted: string;
	};
	whatsapp: string;
}

export interface IPrices {
	[index: string]: any;
	starting_price: number;
	truck1: number;
	truck2: number;
	truck3: number;
	prot1: number;
	prot2: number;
	prot3: number;
	up_int1: number;
	up_int2: number;
	up_int3: number;
	up_driver: number;
	up_cancel: number;
	deposit: number;
	deposit_waiver: number;
	penalty_fuel: number;
	abo: number;
}

export interface IPartnerLocation {
	name: string;
	address: {
		country: string;
		postcode: string;
		street: string;
		houseno: string;
		addition: string | null;
	};
	map: {
		lat: number;
		lng: number;
	};
}

export interface IPartner {
	id: string;
	name: string;
	route_name: string;
	url: string[];
	t_key: string;
	logo: string;
	logo_width: number | null;
	logo_height: number | null;
	map_icon: string;
	locations: IPartnerLocation[];
}

export interface IMediaLogos {
	size?: {
		width: number;
		height: number;
	};
	link: string;
	img: string;
	description: string;
	background?: boolean;
}

export interface IDataPerBu {
	youtube_id: string;
	partner_youtube_id: string;
	phone_fee: number | null;
	email_jobs: string;
	email_fleet_partner: string;
	email_location_partner: string;
	long_term_rent_allowed: boolean;

	partners: IPartner[];

	trucks: {
		min_size_in_m3: {
			l: number;
			xl: number;
			xxl: number;
		};
		price_filter: {
			min: number;
			max: number;
			step: number;
		};
	};

	map_center: {
		lat: number;
		lng: number;
		zoom: number;
	};
	map_center_mobile: {
		lat: number;
		lng: number;
		zoom: number;
	};

	images: {
		home_pricing_cards: {
			l: string;
			xl: string;
			xxl: string;
		};
		truck_side_mockups: {
			l: string;
			xl: string;
			xxl: string;
			advantages: string;
		};
		jobs: {
			second_box: string;
		};
		sustainability: {
			second_box: string;
		};
		our_app: {
			top_section: string;
			how_section: string;
			discover_section: string;
			dl_section: string;
		};
		about_us: {
			second_image: string;
			fourth_image: string;
		};
		location_partner: {
			van: string;
		};
	};
	media_logos: IMediaLogos[][];
}

export class IDateTimeSearch extends SerializeStoreObject {
	start: string | null;
	end: string | null;
	truck?: any;

	constructor() {
		super();

		this.start = null;
		this.end = null;
		this.truck = null;
	}
}

export class ApiCustomerReview {
	rating: number;
	user: string | null;
	title: string | null;
	text: string | null;
	timestamp: Date | null;

	constructor(rating: number, user: string | null, title: string | null, text: string | null, timestamp: string | null) {
		this.rating = rating;
		this.user = user;
		this.title = title;
		this.text = text;
		this.timestamp = null;

		if (timestamp !== null) {
			try {
				this.timestamp = new Date(timestamp);
			} catch (_error) {}
		}
	}
}

export class ISnackbar extends SerializeStoreObject {
	color?: string;
	content: string;
	position?: string;
	time?: number;
	type: string;
	errorDetails?: string;

	constructor() {
		super();

		this.color = '';
		this.content = '';
		this.position = 'top';
		this.time = 0;
		this.type = '';
		this.errorDetails = '';
	}
}

export class IMetaData extends SerializeStoreObject {
	title: {
		hid: string;
		name: string;
		content: string;
	};

	desc: {
		hid: string;
		name: string;
		content: string;
	};

	mainDesc: {
		hid: string;
		name: string;
		content: string;
	};

	constructor(title: string, desc: string) {
		super();

		this.title = {
			hid: `og:${title}`,
			name: `og:${title}`,
			content: title
		};
		this.desc = {
			hid: `og:${title} Description`,
			name: 'og:description',
			content: desc
		};
		this.mainDesc = {
			hid: 'Description',
			name: 'description',
			content: desc
		};
	}
}

export class JsonLD extends SerializeStoreObject {
	'@context': string;
	'@type': string[];
	'name': string;
	'image': string;
	'description': string;
	'brand': {
		'@type': string;
		name: string;
	};

	'telephone': string;
	'priceRange': string;
	'address': {
		'@type': string;
		addressCountry: string;
		postalCode: string;
		addressLocality: string;
	};

	'aggregateRating': {
		'@type': string;
		ratingValue: number;
		bestRating: number;
		worstRating: number;
		ratingCount: number;
	};

	'offers': {
		'@type': string;
		url: string;
		price: number;
		priceCurrency: string;
		availability: string;
		priceValidUntil: string;
	};

	constructor(params?: {
		title?: string;
		desc?: string;
		rating_value?: number;
		rating_count?: number;
		telephone?: string;
		url?: string;
		country?: string;
		postcode?: string;
		city?: string;
		price?: number;
	}) {
		super();
		let rating_value = params?.rating_value ?? MISC_DATA.basedata.reviews.value;
		rating_value = rating_value < 1 ? MISC_DATA.basedata.reviews.value : rating_value;

		let rating_count = params?.rating_count ?? MISC_DATA.basedata.reviews.count;
		rating_count = rating_count < 1 ? MISC_DATA.basedata.reviews.count : rating_count;

		const current_domain = MISC_DATA.current_domain;

		this['@context'] = 'https://schema.org/';
		this['@type'] = ['AutoRental', 'Product'];
		this.name = params?.title ?? '123-Transporter | Transporter & Kleintransporter mieten';
		this.image = global.data().staticAssetsPath + '/img/123transporter-truck.webp';
		this.description =
			params?.desc ?? 'Finde deinen günstigen Miettransporter an über 30 Standorten. 24/7 Anmieten ✓ Ab 45€/Tag ✓ Unbegrenzt Kilometer und Autobahnvignette inklusive.';
		this.brand = {
			'@type': 'Brand',
			name: '123-Transporter'
		};
		this.telephone = MISC_DATA.basedata.bu_info.contact.phone.paid || '00430900340010';
		this.priceRange = '€';
		this.address = {
			'@type': 'PostalAddress',
			addressCountry: params?.country || '',
			postalCode: params?.postcode || '',
			addressLocality: params?.city || ''
		};
		this.aggregateRating = {
			'@type': 'AggregateRating',
			ratingValue: rating_value,
			bestRating: 5,
			worstRating: 1,
			ratingCount: rating_count
		};
		this.offers = {
			'@type': 'Offer',
			url: MISC_DATA.base_url[current_domain] + (params?.url || ''),
			price: params?.price || 45,
			priceCurrency: 'EUR',
			availability: 'https://schema.org/OnlineOnly',
			priceValidUntil: dayjs().add(1, 'year').format('YYYY-MM-DD')
		};
	}
}
