import Site from './site';
import Model from './model';
import Batch from './batch';
import Partner from './partner';
import Downtime from './downtime';
import { AModel, SerializeStoreObject } from '@/types/class_helper';

export enum TruckState {
	Retired = 'R',
	Active = 'A',
	Service = 'S1',
	UnplannedDowntime = 'SU',
	PlannedDowntime = 'SP',
	OnTheWay = 'O',
	Check = 'C'
}

export enum TabletState {
	Active = 'A',
	NotInstalled = 'I',
	HardwareFault = 'H',
	SoftwareFault = 'S',
	UnknownError = 'U'
}

export type TruckSlotInfo = {
	downtime?: Downtime;
	time_start: Date;
	time_end: Date;
	type: string;
};

export type TruckProtectionPrices = {
	[id: string]: {
		amount: number;
		currency: string;
	};
	BASIC: {
		amount: number;
		currency: string;
	};
	STANDARD: {
		amount: number;
		currency: string;
	};
	ZERO: {
		amount: number;
		currency: string;
	};
	SMART_EASY: {
		amount: number;
		currency: string;
	};
};

export type TruckMinPrices = {
	[id: string]: {
		price_24: number;
		price_6: number;
		currency: string;
	};
	L: {
		price_24: number;
		price_6: number;
		currency: string;
	};
	XL: {
		price_24: number;
		price_6: number;
		currency: string;
	};
	XXL: {
		price_24: number;
		price_6: number;
		currency: string;
	};
};

export type TruckTelemetryStatus = {
	gps: {
		timestamp: string;
		lat: number;
		lng: number;
		dist_home: number;
	};
	tablet: {
		timestamp: string;
		network: string;
		version: string;
	};
	telemetry: {
		board_voltage: number;
		central_lock: boolean;
		fuel_level: number;
		ignition: boolean;
		immobilizer: boolean;
		keyfob: boolean;
		mileage: number;
		timestamp: string;
	};
};

export type ITruckListFetchParams = {
	min_lat: string;
	max_lat: string;
	min_lng: string;
	max_lng: string;
	start?: string | null;
	end?: string | null;
	partner_id?: string | null;
};

export type ITruckSlotsFetchParams = {
	truck_id: string;
	truck_location: string | number;
	start: string;
	end: string;
};

export type ICalendarTimeSlot = {
	available: boolean;
	date: string;
	day: number;
	start: string;
	end: string;
};

export class ITruckFilterSettings extends SerializeStoreObject {
	available: boolean;
	unavailable: boolean;
	hook: boolean;
	no_stickers: boolean;
	length_range: number[];
	volume_range: number[];
	seats_3: boolean;
	sizes: string[];
	max_price: string;

	constructor() {
		super();

		this.available = true;
		this.unavailable = false;
		this.hook = false;
		this.no_stickers = false;
		this.length_range = [200, 500];
		this.volume_range = [3, 21];
		this.seats_3 = false;
		this.sizes = [];
		this.max_price = '';
	}
}

export class Truck extends AModel {
	id: string;
	bu: string;
	state: TruckState;
	name: string;
	number: string;
	vin: string;

	model: string | Model | null;
	partner: string | Partner | null;
	batch: string | Batch | null;
	site: string | Site | null;
	location: string | Site | null;

	color: string;
	mileage: number;
	start_mileage: number;
	plate: string;
	safety_valid_to: string;
	initial_register: string;
	last_cleaning: string;
	has_stick_on: boolean;
	last_service: {
		date: string;
		mileage: number;
	};

	invers: {
		qnr: string;
		serial_number: string;
	};

	tablet: {
		id: string;
		state: TabletState;
	};

	gps: {
		id: string;
		sim: string;
	};

	short_link: string | null;

	upcoming_deadlines: {
		[index: string]: string | null;

		cleaning: string | null;
		safety: string | null;
		service: string | null;
	};

	pictures: {
		[index: string]: string | null;

		front: string | null;
		front_driver: string | null;
		driver: string | null;
		co_driver: string | null;
		back: string | null;
		drivers_cab: string | null;
		driver_open: string | null;
		co_driver_open: string | null;
	};

	documents: {
		[index: string]: string | null;

		insurance: string | null;
		manual: string | null;
		registration: string | null;
	};

	'min-price': {
		price_24: number;
		price_6: number;
		currency: string;
	};

	constructor(opt?: Partial<Truck>) {
		super();

		// Default values (all props)
		this.id = '';
		this.bu = 'AT';
		this.state = TruckState.OnTheWay;
		this.name = '';
		this.number = '';
		this.vin = '';
		this.color = '';
		this.mileage = 0;
		this.start_mileage = 0;
		this.plate = '';
		this.safety_valid_to = '';
		this.initial_register = '';
		this.last_cleaning = '';
		this.has_stick_on = false;
		this.last_service = {
			date: '',
			mileage: 0
		};

		this.model = null;
		this.partner = null;
		this.batch = null;
		this.site = null;
		this.location = null;

		this.invers = {
			qnr: '',
			serial_number: ''
		};

		this.tablet = {
			id: '',
			state: TabletState.NotInstalled
		};
		this.gps = {
			id: '',
			sim: ''
		};
		this.short_link = null;

		this.upcoming_deadlines = {
			cleaning: null,
			safety: null,
			service: null
		};

		this.pictures = {
			front: null,
			front_driver: null,
			driver: null,
			co_driver: null,
			back: null,
			drivers_cab: null,
			driver_open: null,
			co_driver_open: null
		};
		this.documents = {
			insurance: null,
			manual: null,
			registration: null
		};

		this['min-price'] = {
			price_24: 0,
			price_6: 0,
			currency: ''
		};

		if (opt) {
			// Sanitize the input to keep only the fields that are needed for this model
			const sanitized = Object.assign(
				{},
				...Object.keys(this).map((key) => ({ [key]: (key in opt ? opt : this)[key] }))
			);

			// Assign values passed to the constructor
			Object.assign<Truck, Partial<Truck>>(this, sanitized);
		}

		// Convert objects to classes
		if (this.site && typeof this.site !== 'string') {
			this.site = new Site(this.site);
		}
		if (this.location && typeof this.location !== 'string') {
			this.location = new Site(this.location);
		}
		if (this.model && typeof this.model !== 'string') {
			this.model = new Model(this.model);
		}
		if (this.partner && typeof this.partner !== 'string') {
			this.partner = new Partner(this.partner);
		}
		if (this.batch && typeof this.batch !== 'string') {
			this.batch = new Batch(this.batch);
		}
	}

	copy(): Truck {
		const copy = new Truck(this.toObject());
		return copy;
	}

	toDTO(): Record<string, unknown> {
		const dto = this.toObject();
		delete dto.documents;
		delete dto.pictures;
		delete dto.site;
		delete dto.location;
		delete dto.partner;
		delete dto.last_cleaning;
		delete dto.last_service;

		if (this.model instanceof Model) {
			dto.model = this.model.id;
		}

		if (this.batch instanceof Batch) {
			dto.batch = this.batch.id;
		}

		return dto;
	}
}

export interface ITruckGroupBySize {
	[key: string]: any;
	l?: Truck[];
	xl?: Truck[];
	xxl?: Truck[];
}
